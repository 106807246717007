@font-face {
    font-family: neon-sign;
    src: url(../src/assets/fonts/Clip.ttf);
    font-display: swap;
}

$lightGray: #d3d3d3;
$darkGray: #101010;
$borderRadius: 18px;
$rainbowGradient: linear-gradient(
    to right,
    hsl(0, 100%, 50%),
    hsl(30, 100%, 50%),
    hsl(60, 100%, 50%),
    hsl(90, 100%, 50%),
    hsl(120, 100%, 50%),
    hsl(150, 100%, 50%),
    hsl(180, 100%, 50%),
    hsl(210, 100%, 50%),
    hsl(240, 100%, 50%),
    hsl(270, 100%, 50%),
    hsl(300, 100%, 50%),
    hsl(330, 100%, 50%),
    hsl(360, 100%, 50%)
);

* {
    box-sizing: border-box;
    margin: 0;
}

body {
    background-color: $darkGray;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;


    a {
        .icon {
            height: 2rem;
            width: 2rem;
        }
    }
}

.app {
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
}

.header {
    padding: 5em 0 3em;

    h1 {
        text-align: center;
        font: normal 48px neon-sign;
        color: rgb(46, 255, 231);
        text-shadow:
            2px 2px 3px rgb(46, 255, 231),
            0px 0px 4px rgb(25, 166, 213),
            0px 0px 5px rgb(25, 166, 213),
            0px 0px 6px rgb(22, 173, 223),
            0px 0px 8px rgb(22, 173, 223),
            0px 0px 10px rgb(22, 173, 223),
            0px 0px 20px rgb(22, 173, 223),
            1px 1px 30px rgb(22, 173, 223);
    }
}

.main {
    display: flex;
    justify-content: center;
    gap: 2em;
    padding: 2em;

    &>* {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2em;
        max-width: 30rem;
    }

    &-right {
        background-color: whitesmoke;
        border-radius: $borderRadius;
        padding: 2em;
        max-height: 350px;
    }

    @media (width <= 800px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        &>* {
            width: 100%;
        }

        &-left {
            flex: none;
        }

        &-right {
            padding: 1em;
        }
    }
}

.text-output {
    width: 100%;
    background-color: black;
    color: whitesmoke;
    font-family: neon-sign;
    font-size: 3em;
    padding: 0 1em;
    word-break: break-word;
    height: 7em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: $borderRadius;

    @media (width <=800px) {
        height: 5em;
        padding: 0 1rem;
    }
}

.code-output {
    width: 100%;
    font-size: 1rem;
    text-align: left;

    @media (width <= 800px) {
        font-size: initial;
    }
}

.options {
    width: 100%;
}

#color {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    background: $rainbowGradient;
    margin-top: 10px;
    border-radius: 10px;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background: white;
        opacity: .5;
        cursor: pointer;
        border: 2px solid $lightGray;
        box-shadow: 1px 1px 1px $darkGray, 0px 0px 1px $darkGray;
    }

    &:focus {
        outline: none;
    }

    &::-ms-track {
        width: 100%;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }
}

.footer {
    background-color: lightgrey;
    padding: 10px;
    text-align: right;
}
